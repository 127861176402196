import React from "react";
import { hydrate } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { ensureReady, After } from "@jaredpalmer/after";
import routes from "./client/routes";
import withRoot from "./client/App";

ensureReady(routes).then(data => {
  const Root = withRoot(After);
  return hydrate(
    <BrowserRouter>
      <Root data={data} routes={routes} />
    </BrowserRouter>,
    document.getElementById("root")
  );
});

if (module.hot) {
  module.hot.accept();
}
