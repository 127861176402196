// @flow

import { asyncComponent } from "@jaredpalmer/after";
import { registeredRolesGroup, type UserType } from "../types";

const routes = [
  {
    path: "/",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/login")
    }),
    allowedRoles: []
  },
  {
    path: "/update_password",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/reset-password")
    }),
    allowedRoles: []
  },
  {
    path: "/pay/:invoiceId",
    component: asyncComponent({
      loader: () => import("./pages/pay-invoice")
    }),
    allowedRoles: []
  },
  {
    path: "/login",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/login")
    }),
    allowedRoles: []
  },
  {
    path: "/generate",
    component: asyncComponent({
      loader: () => import("./pages/generate")
    }),
    allowedRoles: registeredRolesGroup
  },
  {
    path: "/search",
    component: asyncComponent({
      loader: () => import("./pages/search")
    }),
    allowedRoles: registeredRolesGroup
  },
  {
    path: "/invoice",
    component: asyncComponent({
      loader: () => import("./pages/invoices")
    }),
    allowedRoles: ["partner-admin"]
  },
  {
    path: "/coaching",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/coaching")
    }),
    allowedRoles: ["partner-admin"]
  },
  {
    path: "/coaching/:studentId",
    component: asyncComponent({
      loader: () => import("./pages/student")
    }),
    allowedRoles: ["partner-admin"]
  },
  {
    path: "/qrcode-links",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/qrcodelink")
    }),
    allowedRoles: ["site-admin"]
  },
  {
    path: "/partners",
    exact: true,
    component: asyncComponent({
      loader: () => import("./pages/partners")
    }),
    allowedRoles: ["site-admin"]
  },
  {
    path: "/partners/:id",
    component: asyncComponent({
      loader: () => import("./pages/partner-view")
    }),
    allowedRoles: ["site-admin"]
  },
  {
    path: "/email-templates/:id",
    component: asyncComponent({
      loader: () => import("./pages/email-template-edit")
    }),
    allowedRoles: ["site-admin"]
  },
  {
    path: "/email-templates",
    component: asyncComponent({
      loader: () => import("./pages/email-templates")
    }),
    allowedRoles: ["site-admin"]
  },
  {
    path: "/code-email-template",
    component: asyncComponent({
      loader: () => import("./pages/email-template-edit")
    }),
    allowedRoles: ["partner-admin"]
  },
  {
    path: "/email-preview/:purpose",
    component: asyncComponent({
      loader: () => import("./pages/email-preview")
    }),
    allowedRoles: ["site-admin"]
  },
  {
    path: "/voucher-type-templates",
    component: asyncComponent({
      loader: () => import("./pages/voucher-type-templates")
    }),
    allowedRoles: ["site-admin"]
  },
  {
    path: "/pdf-voucher-template-info",
    component: asyncComponent({
      loader: () => import("./pages/pdf-template-info")
    }),
    allowedRoles: ["site-admin"]
  }
];

export default routes;

export const redirect = (user: UserType, match: { path: string }) => {
  var redirect = "";
  routes.some(route => {
    if (route.path === match.path) {
      if (route.allowedRoles.length === 0) {
        return false;
      }
      if (!route.allowedRoles.includes(user.role)) {
        redirect = "/";
      }
      return true;
    }
    return false;
  });
  return redirect;
};
