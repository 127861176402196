// @flow

type ObjectId = {
  toString: () => string,
  equals: (another: ObjectId) => boolean
};

export type SupportedPlatformsType =
  | "android"
  | "osx"
  | "windows"
  | "web"
  | "ios";

export type APIResponseShape<T> = {
  result: {
    status: boolean,
    data?: T,
    error?: ?string
  }
};

export type UserRoleType =
  | "partner-user"
  | "partner-admin"
  | "site-admin"
  | "unregistered";

export type UserStatusType = "active" | "inactive" | "pending";
export type UserType = {
  role: UserRoleType,
  email: string,
  id: string,
  name: string,
  surname: string,
  status: UserStatusType,
  is_stripe_customer_email: boolean,
  language: string
};
export type UserFullType = UserType & {
  password: string,
  reset_token: {
    token: string,
    expire_date: Date
  },
  fullName: string,
  _id: ObjectId,
  toJSON: () => UserType
};

export const registeredRolesGroup = [
  "partner-user",
  "partner-admin",
  "site-admin"
];

export const defaultUser: UserType = {
  role: "unregistered",
  email: "",
  id: "",
  name: "",
  surname: "",
  status: "inactive",
  fullName: "",
  is_stripe_customer_email: false,
  language: "de"
};

export type EmailTemplateType = {
  name: string,
  body: string,
  subject: string,
  purpose: string,
  id: string,
  info: string
};

// type definition for the voucher's type
export type VoucherTypeType = {
  icon: string,
  name: string,
  price: string,
  links: Array<{
    url: string,
    platform: SupportedPlatformsType
  }>,
  credits: number,
  id: string,
  pdf: string,
  status: "active" | "inactive",
  qrCodeLink: string, // id of qrcodelink
  voucherify: {
    product_id: string,
    name: string,
    metadata: Object
  }
};

export type VoucherTypeFullType = VoucherTypeType & {
  _id: ObjectId,
  stripe?: {
    plan_id: string,
    product_id: string,
    subscription_item_id: string
  },
  toJSON: () => VoucherTypeType
};

export type PartnerBaseType = {
  name: string,
  generatedVouchers?: number,
  address: string,
  plz: string,
  ort: string,
  id: string,
  land: string,
  fullAddress?: string,
  status?: "active" | "inactive",
  vouchersDailyLimit?: number,
  billing: "day" | "month" | "week" | "year",
  coaching: boolean,
  codeEmailTemplate?: {
    body: string,
    subject: string,
    info: string
  },
  onlyCredits: boolean,
  userDefinedSetting?: any
};

export type PartnerType = PartnerBaseType & {
  users: Array<UserType>,
  voucherTypes: Array<VoucherTypeType>,
  nextBillingDate?: Date,
  overdueInvoices?: Array<InvoiceType>, // eslint-disable-line no-use-before-define
  unpaidInvoices?: Array<InvoiceType>, // eslint-disable-line no-use-before-define
  coachingNotifications: {
    newMessages: number,
    newRequest: number
  }
};

export type PartnerFullType = PartnerBaseType & {
  _id: ObjectId,
  users: Array<UserFullType>,
  voucherTypes: Array<VoucherTypeFullType>,
  stripe?: ?{
    customer_id: string,
    subscription_id: string
  },
  lastInvoiceDate: Date,
  toJSON: () => PartnerType,
  save: () => PartnerFullType
};

// type definition for a single voucher
export type VoucherStatus = "redeemed" | "not-redeemed" | "unknown";
export type VoucherBaseType = {
  date: string,
  code: string,
  email: string,
  name: string,
  price: number,
  status: VoucherStatus,
  creator: UserType,
  partner: PartnerType
};
export type VoucherType = VoucherBaseType & {
  id: string,
  voucherType: string,
  usingCredit: boolean
};

export type VoucherFullType = VoucherBaseType & {
  _id: ObjectId,
  voucherType: VoucherTypeFullType,
  stripe?: {
    usage_record: string
  },
  toJSON: () => VoucherType
};

export const defaultPartner = {
  name: "",
  generatedVouchers: 0,
  address: "",
  plz: "",
  ort: "",
  id: "",
  land: "",
  status: "active",
  vouchersDailyLimit: 30,
  users: [],
  billing: "week",
  voucherTypes: [],
  stripe: {
    customer_id: "",
    subscription_id: ""
  },
  coaching: true,
  coachingNotifications: {
    newMessages: 0,
    newRequest: 0
  },
  onlyCredits: false
};

// type definition for the invoice
export type InvoiceType = {
  date: string,
  id: string,
  amount: number,
  paid: boolean,
  due: string,
  paymentLink: string,
  period: {
    start: string,
    end: string
  }
};

export type InvoiceFullType = {
  _id: ObjectId,
  date: Date,
  amount: {
    subtotal: number,
    tax: number,
    taxPercent: number,
    total: number
  },
  status: "paid" | "overdue" | "open",
  partner: PartnerFullType,
  sent: boolean,
  paid: boolean,
  period: {
    start: number,
    end: number
  },
  due: Date,
  receiptNumber: string,
  subscriptionId: string,
  items: Array<{
    id: string,
    amount: number,
    metadata: Object,
    period: {
      end: number,
      start: number
    },
    price: number,
    quantity: number,
    usingCredit: boolean,
    voucherType: VoucherTypeFullType
  }>,
  toJSON: () => InvoiceType,
  save: () => InvoiceFullType
};

export type QRCodeLinkType = {
  flavour: string,
  link: string,
  id: string
};

export type StudentBaseType = {
  createdAt: Date,
  status: "active" | "inactive" | "pending" | "rejected",
  partner: PartnerFullType,
  email: string,
  name: string,
  elearningId: string,
  flavour: string,
  voucher: Array<string>,
  learningSpeed: Array<{
    date: Date,
    red: number,
    green: number,
    yellow: number,
    darkGreen: number
  }>,
  learningQuantity: Array<{
    date: Date,
    count: number
  }>,
  learningProgress: Array<{
    names: Object,
    image: string,
    progress: Array<{
      color: string,
      count: number,
      url: string
    }>
  }>,
  testsHistory: Array<{
    date: Date,
    duration: number,
    score: number,
    percentage: number,
    status: "passed" | "failed",
    testId: string
  }>,
  seenByTeacher: Date,
  overallProgress: number,
  passedTests: number,
  lastActivity: Date
};

export type StudentType = StudentBaseType & {
  id: string,
  voucherifyCodeName: string
};

export type StudentFullType = StudentBaseType & {
  _id: ObjectId
};

export type ListOfStudentsType = Array<{
  id: string,
  email: string,
  newMessageCount: number,
  status: "active" | "inactive" | "pending",
  voucherTypeName: string
}>;

export type StudentDashboardType = StudentType & {
  newMessageCount: number,
  voucherTypeName: string
};

export type MessageFullType = {
  _id: ObjectId,
  createdAt: Date,
  partner: PartnerFullType,
  student: StudentFullType,
  content: string,
  from: "student" | "teacher",
  read: boolean
};

export type MessageType = {
  id: string,
  createdAt: Date,
  partner: {
    id: string,
    name: string
  },
  student: {
    id: string,
    name: string
  },
  content: string,
  from: "student" | "teacher",
  read: boolean
};

export type QuestionType = {
  id: string,
  code: string,
  text: string,
  explanation: string,
  answers: Array<{
    id: string,
    code: string,
    names: Object,
    iscorrect: string,
    number: string
  }>
};

export type TestSessionType = {
  id: string,
  flavour: string,
  created: Date,
  ended: Date,
  score: number,
  maxScore: number,
  percentage: number,
  points: number,
  isPassed: boolean,
  rating: number,
  maxErrorPoints: number,
  duration: number,
  elapsed: number,
  questionSet: string,
  questions: Array<
    QuestionType & {
      reply: {
        id: string,
        selectedAnswers: [String],
        enteredAnswer: String
      }
    }
  >
};
