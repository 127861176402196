// @flow

import * as React from "react";

import {
  type UserType,
  type VoucherTypeType,
  type PartnerType,
  defaultUser,
  defaultPartner
} from "../../types";
import { redirect } from "../routes";

//the default type for sharedData which is available to other components via SharedDataContext
export type SharedDataType = {
  loggedInUser: UserType,
  voucherTypes: Array<VoucherTypeType>,
  partnerOfLoggedInUser?: PartnerType
};
export const defaultSharedData: SharedDataType = {
  loggedInUser: defaultUser,
  voucherTypes: [],
  partnerOfLoggedInUser: defaultPartner
};

// the context which contains the sharedData for the app.
// the initial value of the sharedData is set server-side in App.js
export type MainContextType = {
  sharedData: SharedDataType,
  updatePartnerOfLoggedInuser: (partner: PartnerType) => any
};
const SharedDataContext: React$Context<MainContextType> = React.createContext({
  sharedData: defaultSharedData,
  updatePartnerOfLoggedInuser: partner => {}
});

export default SharedDataContext;

// this function wrap the Component with SharedDataContext consumer.
// thus, the component will receive the sharedData as props.
export const withSharedData = (Component: {
  getInitialProps?: ?(context: any) => any,
  ...$Subtype<React.ComponentType<any>>
}) => {
  class SharedDataContextComponent extends React.Component<any> {
    static getInitialProps: ?(ctx: any) => any;
    render() {
      return (
        <SharedDataContext.Consumer>
          {({ sharedData, updatePartnerOfLoggedInuser }) => (
            <Component
              {...this.props}
              {...sharedData}
              updatePartnerOfLoggedInuser={updatePartnerOfLoggedInuser}
            />
          )}
        </SharedDataContext.Consumer>
      );
    }
  }
  if (Component.getInitialProps) {
    SharedDataContextComponent.getInitialProps = Component.getInitialProps;
  }

  return SharedDataContextComponent;
};

// in this function we populate the shared data in the server,
// then call the getInitialProps of the top route component of the page
type ContextType = {
  req: any,
  res: { locals: Object },
  loggedInUser: any,
  match: any
} & Object;
export function getInitialPropsWithInitialData(
  componentGetInitialProps: (
    ctx: ContextType,
    initialData: { ...$Shape<SharedDataType> }
  ) => any
) {
  return async (context: ContextType) => {
    var componentData = {};
    var sharedInitialData = {};
    try {
      if (context.res) {
        // the "redirect" function takes current path and check if it needs to be redirected.
        // if the return value of "redirect" function is not empty string, we tell the server to redirect the user.
        // in server.js, the value of res.locals.redirect is read.
        const redirectPath = redirect(context.loggedInUser, context.match);
        if (redirectPath) {
          context.res.locals.redirect = redirectPath;

          // the user will be redirected somewhere else, so we don't need to get the data for this component
          return {};
        }

        // populate the shared initial data value from values passed to the "render" function in server.js.
        // to add more values to sharedInitialData, pass more arguments to the "render" function in server.js.
        sharedInitialData = {
          voucherTypes: context.partnerOfLoggedInUser
            ? context.partnerOfLoggedInUser.voucherTypes
            : [],
          loggedInUser: context.loggedInUser,
          partnerOfLoggedInUser: context.partnerOfLoggedInUser
            ? context.partnerOfLoggedInUser
            : undefined
        };
      }

      // call the component's getInitialProps to get the data for the component.
      // we pass the sharedInitialData too just in case the component needs it.
      componentData = await componentGetInitialProps(
        context,
        sharedInitialData
      );
    } catch (error) {
      console.error(error);
    }

    if (context.res) {
      // on server side, we feed the root of the app (App.js) with componentData and sharedInitialData.
      // the value of sharedInitialData is used as the initial value of the SharedDataContext. Other components can read this value by using SharedDataContext's consumer.
      // the value of componentData is passed to the component as props.
      return {
        componentData,
        sharedInitialData
      };
    }

    // on the client side, we are not updating the SharedDataContext value. so we just return the component's data immediately.
    return { ...componentData };
  };
}
