// @flow

const theme = {
  headerHeight: 45,
  footerMinHeight: 90,
  colors: {
    darkBlue: "rgba(0, 20, 39, 1)",
    orange: "rgba(238, 99, 82, 1)",
    blue: "rgba(71, 108, 155, 1)",
    lightBlue: "rgba(8, 178, 227, 1)",
    whitePurple: "rgba(239, 233, 244, 1)",
    brightBlue: "#08c6fd",
    slightlyDarkBlue: "#037494",
    disabled: "#d4d4d4",
    yellow: "#FFD231"
  }
};

export type ThemeType = typeof theme;

export type ThemedComponentType = {
  theme: ThemeType,
  active?: boolean,
  disabled?: boolean
};

export default theme;
