import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import theme from "./theme";

import "./App.css";

import SharedDataContext from "./contexts/shared-data";

// The Root component wraps the routes components:
// - ThemeProvider from styled-components
// - SharedDataContext provider
const withRoot = After => {
  return function Root(props) {
    const {
      data = { sharedInitialData: {}, componentData: {} },
      ...rest
    } = props;
    const { sharedInitialData, componentData } = data;
    const [sharedData, setSharedData] = useState(sharedInitialData);
    return (
      <ThemeProvider theme={theme}>
        <SharedDataContext.Provider
          value={{
            sharedData,
            updatePartnerOfLoggedInuser: newPartner => {
              setSharedData({
                ...sharedData,
                partnerOfLoggedInUser: newPartner
              });
            }
          }}
        >
          <After {...rest} data={{ ...componentData }} />
        </SharedDataContext.Provider>
      </ThemeProvider>
    );
  };
};

export default withRoot;
